.service-card--wrapper{
    display: grid;
    place-content: center;
    max-width: 500px;
    & .content{
        text-align: center;
        max-width: 350px;
        & img{
            width: 80%; 
            height: 250px;
            object-fit: cover;
        }
        & h3{
            color: var(--bg-secondary);
            height: 40px;
        }
        & p{
            margin: 20px auto 10px;
            height: 100px;
            width: 80%; 
            color: var(--text-secondary);
            font-weight: 400;
        }
        & a{
            border: 2px solid var(--text-secondary);
            border-radius: 10px;
            color: var(--text-secondary);
            background-color: var(--bg-secondary);
            padding: 5px 10px;
            text-decoration: none;
        }
    }
    @media screen and (max-width: 450px){
        background: linear-gradient(0deg, #FFFFFF00 65%, var(--bg-secondary) 0%);
        color: var(--text-accent);
        & .content{
            text-align: center;
            max-width: 350px;
            margin: 10px 0px 50px;
            & img{
                width: calc(100vw - 60px); 
                height: 30vh;
                object-fit: cover;
            }
            & h3{
                color: var(--text-secondary);
                height: 40px;
            }
            & p{
                margin: 10px auto;
                height: 100px;
                width: 80%; 
                color: var(--text-accent);
            }
            & a{
                border: 2px solid var(--text-accent);
                color: var(--text-accent);
                background-color: var(--text-secondary);
            }
        }
    }
}