.home--wrapper{
    overflow-x: hidden;
    & #hero{
        & img{
            width: 100%;
            height: calc(100vh - 150px);
            object-fit: cover;
        }
        & .dots-holder{
            position: absolute;
            transform: translateY(-100%);
            & button, button:hover, button:focus, button:active{
                background-color: transparent;
                &.BrainhubCarousel__dot--selected{
                    &:before{
                        background: var(--text-accent) !important;
                    }
                }
                &:before{
                    height: 10px;
                    width: 10px;
                    background: var(--text-secondary);
                }
            }            
            right: 20px;
        }
        & .list{
            position: absolute;
            transform: translateY(-100%);
            left: 80px;
            & .title{
                font-size: 3rem;
                background-color: var(--bg-primary);
                padding: 15px 40px;
                font-weight: bold;
                color: var(--text-accent);
            }
            & .description{
                font-size: 2.5rem;
                background-color: var(--bg-secondary);
                padding: 15px 40px;
                font-weight: bold;
                color: var(--text-secondary);
                & svg{
                    transform: translateY(5px);
                }
            }
        }
        @media screen and (max-width: 800px){
            & img{
                height: 50vh;
            }
            & .list{
                position: static;
                transform: none;
                & .title{
                    font-size: 1.5rem;
                    background-color: var(--text-accent);
                    padding: 10px 30px;
                    color: var(--text-secondary);
                }
                & .description{
                    font-size: 1rem;
                    background-color: var(--bg-primary);
                    padding: 15px 40px;
                    color: var(--text-accent);
                    & svg{
                        height: 20px;
                        width: 20px;
                        transform: translateY(5px);
                        path{
                            fill: var(--text-accent);
                        }
                    }
                }
            }
        }
    }
    & #services{
        display: grid;
        padding-top: 100px;
        & h2{
            margin-bottom: 50px;
        }
        & .list{
            overflow-x: scroll;
        }
        & .list-inner{
            display: grid;
            padding-bottom: 80px;
            grid-template-columns: repeat(4, auto);
            min-width: 1800px;
            overflow-x: scroll;
            place-content: center;
            background: linear-gradient(180deg, #FFFFFF00 20%, var(--bg-secondary) 0%);
        }
        & p.note{
            max-width: 950px;
            margin: 50px auto;
            font-size: 1.5rem;
            text-align: center;
            & span{
                font-weight: bold;
                color: var(--text-accent);
            }
            
        }
        @media screen and (max-width: 650px){
            & .list-inner{
                min-width: 1300px;
            }
        }
        @media screen and (max-width: 450px){
            padding-top: 150px;
            & .list{
                overflow-x: hidden;
            }
            & .list-inner{
                min-width: 0px;
                padding-bottom: 20px;
                grid-template-columns: 1fr;
                background: var(--text-secondary);
            }
            & p.note{
                padding: 40px;
                margin: 0px auto;
                font-size: 1rem;
                
            }
        }
    }
    & #testimonials{
        display: grid;
        padding-top: 150px;
        & h2{
            margin-bottom: 50px;
        }
        & img{
            width: 60vw;
            place-self: center;
            margin-top: 80px;
        }
        & .quote{
            text-align: center;
            max-width: 1000px;
            h4{
                font-weight: 400;
                font-size: 1.8rem;
                margin: 30px 0px 0px;
            }
        }
        @media screen and (max-width: 800px){
            & img{
                width: calc(100vw - 40px);
                margin: 80px auto 0px;
            }
            & .dots-holder{
                display: block;
            }
            & .quote{
                box-sizing: border-box;
                padding: 0px 20px;
                h4{
                    font-size: 1.2rem;
                    padding: none;
                }
            }
        }
    }
    & #about-us{
        padding: 100px 0px;
        display: grid;
        & img{
            width: 60vw;
            height: 40vw;
            object-fit: cover;
            margin: 0 auto;
        }
        & p{
            color: var(--text-header);
            text-align: justify;
            padding: 40px 0px;
            width: 60vw;
            font-size: 1.6rem;
            margin: 0 auto;
        }
        @media screen and (max-width: 800px){
            padding: 150px 0px 0px;
            img{
                width: calc(100vw - 60px);
                height: 35vh;
            }
            & p{
                box-sizing: border-box;
                text-align: left;
                padding: 40px 30px;
                width: 100vw;
                font-size: 1rem;
            }
        }
    }
    & #contact-us{
        padding: 150px 40px 60px;
        display: grid;
        grid-template-columns: 1fr 2fr;
        margin: 0 auto;
        width: 60vw;
        min-width: 500px;
        column-gap: 40px;
        & h2{
            grid-column: 1/3;
        }
        & img{
            max-width: 100%;
            height: auto;
            object-fit: cover;
        }
        & iframe{
            height: 100%;
            width: 100%;
            border: none;
        }
        @media screen and (max-width: 800px){
            grid-template-columns: 1fr;
            box-sizing: border-box;
            width: 100vw;
            min-width: 0px;
            & h2{
                grid-column: 1/2;
            }
            & img{
                height: 0px;
            }
            & iframe{
                height: 300px;
            }
        }
    }
    & h2{
        color: var(--text-header);
        margin: 50px auto;
        text-align: center;
        font-size: 3rem;
        width: auto;
        height: 3.6rem;
        padding: 0px 20px;
        @media screen and (max-width: 800px){
            margin: 10px auto;
            font-size: 2.5rem;
        }
    }
    & .section{
        display: grid;
        padding-top: 150px;
        height: calc(100vh - 150px);
        & h2{
            margin: 0 auto;
        }
    }
}