.compounding--wrapper{
    & label.upload{
        background-color: #A4A4A4;
        display: inline-block;
        cursor: pointer;
        color: var(--text-secondary);
        border-radius: 10px;
        font-size: 1.5rem;
        margin: 20px auto;
        font-weight: bold;
        padding: 10px 20px;
        border: none;
        @media screen and (max-width: 800px) {
            font-size: 1rem;
            margin: 10px auto;
        }
    }
    & button.expand{
        padding: 0;
        font-family: inherit;
        font-size: 1.5rem;
        background-color: transparent;
        color: rgb(57, 57, 255);
        border: none;
        text-decoration: underline;
        &:active, &:hover, &:focus{
            outline: none;
        }
    }
    & input[type="file"] {
        display: none;
    }
}