.navigation-mobile--icon {
  z-index: 1001;
  position: fixed;
  top: 10px;
  right: 15px;
  cursor: pointer;
  display: none;
  @media screen and (max-width: 850px) {
    display: block;
  }
}

.navigation--wrapper {
  position: sticky;
  box-sizing: border-box;
  z-index: 1000;
  top: 0px;
  height: 100px;
  width: 100vw;
  padding: 20px 40px;
  display: grid;
  grid-template-columns: 310px 1fr repeat(5, auto);
  column-gap: 40px;
  background-color: var(--bg-primary);
  border-bottom: 2px solid var(--text-accent);
  & img {
    height: 60px;
  }
  & button {
    display: grid;
    place-items: center;
    border: none;
    background-color: transparent;
    text-transform: uppercase;
    a.btn {
      color: var(--text-secondary);
      background-color: var(--bg-secondary);
      padding: 10px 20px;
      border-radius: 10px;
      text-transform: uppercase;
    }
  }
  & a {
    display: grid;
    place-items: center;
    color: var(--text-primary);
    text-decoration: none;
    font-size: 1.2rem;
    text-transform: uppercase;
    &:focus {
      outline: 0;
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 1200px) {
    height: 80px;
    column-gap: 20px;
    grid-template-columns: 250px 1fr repeat(5, auto);
    & a {
      font-size: 1rem;
    }
    & img {
      height: 40px;
    }
  }
  @media screen and (max-width: 850px) {
    height: 100px;
    padding: 0px;
    grid-template-columns: 100vw;
    grid-template-rows: 100px repeat(6, 0px);
    &.open {
      grid-template-rows: 100px 0px repeat(5, 70px);
    }
    & img {
      display: block;
      height: 60px;
      width: 100%;
      box-sizing: border-box;
      padding: 10px 30px;
      object-fit: contain;
    }
    & a,
    button {
      overflow: hidden;
      & a.btn {
        background-color: transparent;
        border: none;
        text-transform: capitalize;
      }
      text-transform: capitalize;
      color: var(--text-secondary);
      border-bottom: 1px solid white;
      background-color: var(--text-accent);
    }
  }
}
