.basic-card--wrapper {
  width: 60vw;
  margin: 100px auto;
  display: grid;
  grid-template-columns: 4fr 3fr;
  column-gap: 80px;
  & span {
    font-weight: bold;
    font-size: 3rem;
    width: auto;
    color: var(--text-header);
  }
  & img {
    height: 100%;
    margin: 50px 0px;
    width: 100%;
    object-fit: cover;
  }
  & p {
    font-size: 1.6rem;
  }
  & li {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 1500px) {
    width: calc(100vw - 120px);
    & span {
      font-size: 2.5rem;
    }
    & p {
      font-size: 1.4rem;
    }
    & li {
      font-size: 1.4rem;
    }
  }
  @media screen and (max-width: 850px) {
    margin: 50px auto;
    width: calc(100vw - 80px);
    grid-template-columns: 1fr;
    & span {
      font-size: 1.7rem;
      grid-row: 1/2;
    }
    & img {
      width: calc(100vw - 80px);
      object-fit: cover;
      height: 50vw;
      margin: 10px 0px;
    }
    & p {
      font-size: 1rem;
    }
  }
}

.basic-card--media-section {
  text-align: center;
}
