.footer--wrapper{
    display: grid;
    grid-template-columns: 500px 200px;
    column-gap: 40px;
    row-gap: 60px;
    place-content: center;
    box-sizing: border-box;
    padding: 50px 0 40px;
    width: 100%;
    background-color: var(--bg-secondary);
    color: var(--text-secondary);
    font-size: 1.4rem;
    & .item{
        & span{
            font-weight: bold;
        }
    }
    & .copyright-notice{
        font-size: 1rem;
        grid-column: 1/3;
        text-align: center;
    }
    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        padding: 40px;
        row-gap: 30px;
        font-size: 1.1rem;
        & .copyright-notice{
            grid-column: 1/2;
        }
    }
}